<template>
  <div>
    <common-hero></common-hero>
    <div class="desc-wrapper about-wrapper">
      <vue-custom-scrollbar class="scroll-area">
        <div class="about-section">
          <div>
            <h2>मेल्लेखको नामकरण</h2>
            <p>
              पूर्व अवस्थामा उक्त क्षेत्रमा अन्न वालिको उत्पादन निकै कम हुने
              गर्दथ्यो । यहाँको मुख्य वाली जौ, आलु, मकै, कोदो तथा धान भएतापनि
              खाद्यान्नको अभाव हुने क्षेत्रमा वर्तमानको मेल्लेख गाँउपालिका पनि हो
              । तर घना तथा बाक्लो जंगलको यस क्षेत्रको विशेषता हो । बाक्लो जंगल
              भएतापनि बाक्लो कुकाठको बाहुल्यता अहिले पनि विद्यमान छ । खासगरी १२००
              मिटर भन्दा माथिल्लो भागमा हावापानी (न्यानो समशितोष्ण हावापानी (१२००–
              ३३०० मिटरसम्म) मा पनि विविधता हुने गर्दछ । यो हावापानी खासगरी
              कुकाठको लागि योग्य हुन्छ र यस प्रकारको हावापानी पाईने स्थानलाई लेक
              भनने गरिन्छ । यस उचाईमा रहेको भूभाग खासगरी मेलको बाहुल्यता हाल पनि
              उपलब्ध छ । लेकालि भूभाग र मेल सहितको लेकलाई संगै जोडी मेललेक भन्न
              थालियो । यसरी मेललेकबाट विकास हुदै हाल मेल्लेखको नमाबाट उक्त ठाउँ
              परिचित छ । संविधान कार्यान्वयन स्वरुप स्थानीय तहको निर्वाचन नेपालको
              राजनीतिक पार्टीहरुको पहिलो अनिवार्यता थियो । स्थानीय सरकार निर्माणका
              लागि २०७४ मा स्थानीय तह निर्वाचन सम्पन्न भयो । यस गाउँपालिकामा
              तत्कालिन माओवादी र कांग्रेसको गठबन्धन कायम गरियो । लोकबहादुर बोहरा
              अध्यक्ष (तत्कालिन माओवादी उम्मेदवार) र धनबहादुर शाही (कांग्रसेबाट)
              उपाध्यक्षमा विजयी प्राप्त गरे भने बहुमत वडाहरु तत्कालिन नेकपा
              (एमाले) विजयी हासिल ग¥यो । जसको फलस्वरुप स्थानीय सरकार निर्माण भई
              हाल क्रियासिल छ । हाल लोकबहादुर बोहरा नेपाल कम्युनिष्ट पार्टी
              (नेकपा) को प्रदेश कमिटी सदस्य तथा उक्त पार्टीको निर्वाचित मोर्चा
              प्रमुख हुन ।
            </p>
            <!-- <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a> -->
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Naming",
  components: {
    CommonHero: () => import("./commonHero.vue"),
    vueCustomScrollbar
  }
};
</script>

<style lang="scss" scoped>

</style>
